/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=MazzardH-Regular"); */

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Variables for heading */
  --heading-line-height: 1.2;
  --heading-font-size: 35px;
  --heading-font-weight: 800;
  --heading-font-family: "Rubik", sans-serif;

  /* Variables for paragraph */
  --paragraph-font-size: 1.2rem;
  --paragraph-font-weight: 200;
  --paragraph-font-family: "Rubik", sans-serif;
  --paragraph-letter-spacing: 2px;
}

/* Why is a style for a service heading on the app entry css? */

.general-heading {
  margin-bottom: 1em;
  /* line-height: 1rem; */
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "Rubik";
  text-align: center;
}

.paragraph {
  color: #333;
  font-size: 1rem;

  font-weight: 200;
  color: #333 !important;
  font-family: "Rubik";
  text-align: justify;
  text-align: left;
  /* letter-spacing: 2px; */
}

.main-container {
  padding-left: 100px;
  padding-right: 100px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-inner {
  background-color: #ffffff;
  width: 65%;
  height: 70%;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.modal-x {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-text {
  margin-top: 40px;
  overflow: auto;
  max-height: calc(
    100% - 80px
  ); /* Subtract the height of the header and padding */
}

.modal-header {
  margin-bottom: 10px;
  color: #282a94;
  font-size: 24px;
  font-family: "Rubik";
}

.modal-body {
  color: #000000;
  font-size: 18px;
  font-family: "Rubik";
}
/* AI Section */
.chat-with-ogo {
  position: fixed;
  bottom: 50px;
  right: 100px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 20px;
}
.toast {
  position: fixed;
  right: 20px;
  top: 60px;
  width: 30%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.Ogo-AI-toast {
  width: 40px;
  height: 40px;
}
.chat-with-ogo-p {
  font-family: "Rubik";
  font-weight: 800;
  color: #ffffff;
}
.Ogo-AI {
  width: 60px;
  height: 60px;
}
.inner-AI {
  display: flex;
  justify-content: center;
}
.toast-title {
  font-size: 18px;
  font-weight: 800;
  font-family: "Rubik";
  color: #ffffff;
}
/* Add these keyframes for the shaking animation */
/* @keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
} */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

/* Add a shaking class to trigger the animation */
.shaking {
  animation: shake 0.9s; /* Adjust the duration as needed */
  animation-iteration-count: 1;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .main-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-inner {
    height: 80%;
    width: 90%;
  }
  /* AI section */
  .toast {
    position: fixed;
    right: 0;
    left: 0;
    top: 60px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .Ogo-AI-toast {
    width: 30px;
    height: 30px;
  }
  .chat-with-ogo {
    position: fixed;
    bottom: 50px;
    right: 10px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 20px;
  }
  .chat-with-ogo-p {
    font-family: "Rubik";
    font-weight: 800;
    color: #ffffff;
    font-size: 10px;
  }
  .inner-AI {
    display: flex;
    justify-content: center;
  }
  .Ogo-AI {
    width: 40px;
    height: 40px;
  }
}
