/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-bottom: 20px;
}
.inner-container {
  align-items: center;
  display: grid;
}
.staff-header {
  margin-top: 20px;
  color: #ffffff;
  font-family: "Rubik";
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 30px;
}
.staff-p {
  font-weight: 200;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-family: "Rubik";
}

.input-button {
  width: 300px;
  margin-top: 50px;
  /* background-color: green; */
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .nav-main {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .menu-button {
    display: block;
  }
  .list {
    display: none;
  }
  .nav-link-container {
    display: flex;
  }
  .icons-right {
    display: flex;
    gap: 30px;
    align-items: center;
    color: #282a94;
  }
}
