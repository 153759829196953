@import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular");

* {
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
}

.rubik{
  font-family: 'Rubik';
}

.Courier{
  font-family: "Courier New", Courier, monospace;
}

.playpen{
  font-family: "Playpen";
}

.letterspacing{
  letter-spacing: 2px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll caused by extra width */
}

/* CONTENT */
.home-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  height: 80vh;
}

.banner-size {
  width: 300px;
  height: 300px;
  object-fit: cover;
  background-color: #fff9e9;
  border-radius: 50%;
}

.home-content {
  text-align: center;
  margin-bottom: 2rem;
}

.home-heading {
  font-size: 3rem;
  letter-spacing: 3px;
  /* text-align: center; */
  font-weight: 700;
  color: #071d6a;
  /* text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25); */
  font-family: "Rubik";
}

.product-button {
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.See-our-product {
  font-family: "Rubik";
  cursor: pointer;
  width: 60%;
  margin-top: 20px;
  transition: transform 0.3s ease-in-out;
}

.See-our-product:hover {
  transform: scale(1.1);
}

/* home Banner css */
.home-middle-banner {
  background-color: #111724;
  width: 100%;
  padding: 0 100px;
  display: flex;
  height: 120px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.home-middle-one {
  display: flex;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
  animation: scroll 30s linear infinite;
}

.home-middle-one-icon {
  width: 40px;
  height: 40px;
  color: #97A3B6
}

.home-middle-one-p {
  margin-top: 10px;
  font-size: 16px;
  color: #97A3B6
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* TABLET On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
}

/*MOBILE On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .home-main {
    align-self: center;
    padding: 0 20px;
    height: 50vh;
  }

  .home-heading {
    font-size: 1.8rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .home-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2.3em;
  }
  .home-right {
    display: none;
  }
  .See-our-product {
    font-family: "Rubik";
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    transition: transform 0.3s ease-in-out;
  }

  .See-our-product:hover {
    transform: scale(1.1);
  }
  .home-middle-banner {
    height: 70px;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }

  .home-middle-one-icon {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .home-middle-one {
    display: flex;
    justify-content: center;
    gap: 10px;
    animation: scroll 15s linear infinite;
  }

  .home-middle-one-p {
    font-size: 12px;
  }

  .icon-styles {
    color: #000000;
    width: 15px;
    height: 15px;
  }
}
