/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.all-staff-main {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 60px;
}
.all-staff-inner {
  width: 100%;
  height: 100%;
  background-color: #00000080;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
}
.select-staff {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-text {
  font-weight: 800;
  color: #282a94;
  font-family: "Rubik";
  letter-spacing: 2px;
  font-size: 22px;
}
.input-and-button {
  display: flex;
  gap: 10px;
}
.btn {
  width: 50%;
}
