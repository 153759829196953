.test-image {
  width: 100%;
  height: 100%;
}

.test-hero-section {
  background-color: #f0f0f0;
  display: flex;
  /* justify-content: space-between; */
  gap: 2px;
  align-items: center;
  padding: 0 100px;
}
.test-text-area h2 {
  font-size: 2.7rem;
  font-weight: 600;
  font-family: "Rubik";
  margin-bottom: 1em;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}

.test-text-area p {
  font-size: 1.2rem;
  font-family: "Rubik";
  letter-spacing: 2px;
}

.test-top-con {
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  width: 30px;
}
.back-btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.test-btn-hero {
  padding: 5px;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}
.test-btn-hero:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.test-top-back-arrow {
  width: 30px;
  height: 30px;
}

.test-icon {
  width: 20px;
  height: 20px;
  color: #ffffff;
}

.take-test {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6em;
}

.take-test-btn {
  margin-top: 60px;
  width: 20%;
}

.test-text-area {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 60px;
}

/* TEST MAP */
.padding-map {
  padding: 0 100px;
}

.map-test {
  margin-top: 80px;
}

.map-test-inner {
  margin-top: 20px;
}

.map-test-list {
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  font-family: "Rubik";
}
.custom-checkbox {
  margin-right: 10px;
  width: 15px; /* Increase the width of the checkbox */
  height: 15px; /* Increase the height of the checkbox */
  transform: scale(
    1.5
  ); /* Alternatively, you can use scale to make it bigger */
}

.map-test-ul {
  margin-top: 12px;
  display: flex;
  gap: 20px;
  padding-left: 30px;
}

.btn-parent {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 15%;
}

/* RESULTS */
.results-container {
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 20px 50px;
  background-color: #091d6a;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}
.test-submit-container {
  min-height: 60vh;
}

.submit-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.result {
  margin-bottom: 20px;
  font-size: 2rem;
  font-family: "Rubik";
  /* color: #333; */
  color: #ffffff;
}

.results-container h3 {
  margin-top: 15px;
  font-size: 1.5rem;
  color: #25d366;
}
hr {
  border: 0;
  height: 1px;
  /* background: rgb(186, 184, 184); */
  background: #25d366;
  margin: 20px 0;
}
.passed-resutl {
  color: #02b15a;
  font-family: "Rubik";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.test-apply-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.apply-btn {
  margin-top: 2em;
  width: 13%;
  border-radius: none;
  transition: transform 0.3s ease-in-out;
}
.apply-btn:hover {
  transform: scale(1.1);
}
.failed-test {
  color: #e2b059;
  font-family: "Rubik";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .test-text-area {
    padding-left: 50px;
    padding-right: 50px;
  }

  .padding-map {
    padding: 0 50px;
  }

  .btn-parent {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .test-top-con {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 30px;
  }
  .back-btn-icon {
    width: 35px;
    height: 35px;
    border-radius: 50px;
  }
  .test-btn-hero {
    padding: 5px;
    border: none;
    border-radius: 50px;
  }
  .test-hero-section {
    flex-direction: column; /* Stack elements vertically */
    padding: 30px 15px; /* Further reduce padding */
    gap: 1px; /* Narrow the gap between elements */
  }

  .test-hero-section img {
    width: 100%; /* Ensure the image takes up full width */
    height: auto; /* Maintain aspect ratio */
  }

  .test-text-area h2 {
    font-size: 1.8rem; /* Reduce heading size further */
  }

  .test-text-area p {
    font-size: 15px; /* Reduce paragraph font size for smaller screens */
    letter-spacing: 0.8px; /* Adjust letter spacing */
  }
  /* .test-text-area h2 {
    font-size: 2rem;
  }

  .test-text-area p {
    font-size: 1rem;
  }

  .test-top-con {
    padding-left: 20px;
    width: auto;
  }

  .take-test-btn {
    width: 80%;
  } */

  .test-text-area {
    padding-left: 20px;
    padding-right: 20px;
  }

  .padding-map {
    padding: 0 20px;
  }

  .btn-parent {
    width: 100%;
  }
  .map-test-inner h3 {
    font-size: 15px;
  }

  .map-test-ul {
    padding-left: 10px;
    /* font-size: 15px; */
    color: #6e6a6a;
  }
  .map-test-list {
    text-decoration: none;
    list-style: none;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    font-family: "Rubik";
  }

  .results-container {
    padding: 0 20px;
    /* font-size: 14px; */
  }

  .result {
    font-size: 1.5rem;
  }

  .results-container h3 {
    font-size: 15px;
  }
  .take-test-btn {
    margin-top: 60px;
    width: 100%;
  }
  .results-container {
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 30px 50px;
    background-color: #103791;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
  }
  .failed-test {
    color: #e2b059;
    font-family: "Rubik";
    padding: 0 20px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
}
