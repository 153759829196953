.service-main {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* padding: 40px 100px; */
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  /* margin-bottom: 2rem; */
  text-align: center;
}
/* hero section */
.service-hero-section {
  background-color: #f0f0f0;
  display: flex;
  /* justify-content: space-between; */
  gap: 2px;
  align-items: center;
  padding: 0 100px;
  margin-bottom: 50px;
}

.service-text-area h2 {
  font-size: 5.7rem;
  font-weight: 600;
  font-family: "Rubik";
  margin-bottom: 1em;
  text-align: center;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}
.general-heading-two {
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.service-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  place-items: center;
  padding-top: 3rem;
  list-style: none;
}

.service-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  /* transform: translateY(-5px); */
  scale: 1.05;
}

.service-icon {
  margin-bottom: 1rem;
}

.service-card ul {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  text-align: left;
}

.service-card li {
  margin-bottom: 0.5rem;
}

.read-more-link {
  color: #282a94;
  font-weight: bold;
  cursor: pointer;
}

.read-more-link:hover {
  color: #479be8;
}

.benefits-section {
  margin-top: 3rem;
  max-width: 800px;
}

.benefits-section h2 {
  margin-bottom: 1rem;
}

.benefits-section ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.benefits-section li {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .service-main {
    padding: 20px;
    align-items: center;
  }

  .service-card-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .service-hero-section {
    margin-bottom: 50px;
    flex-direction: column; /* Stack elements vertically */
    padding: 30px 15px; /* Further reduce padding */
    gap: 1px; /* Narrow the gap between elements */
  }
  .general-heading-two {
    color: #071d6a;
    text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
    font-family: "Rubik";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .service-hero-section img {
    margin-bottom: 1.5em;
    width: 100%; /* Ensure the image takes up full width */
    height: 30%; /* Maintain aspect ratio */
  }

  .service-text-area h2 {
    font-size: 1.8rem; /* Reduce heading size further */
  }
}
