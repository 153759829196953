.training-hero {
  padding: 20px 50px;
  margin: 4rem 3rem;
  border-radius: 20px;
  height: 90vh;
  background: rgba(107, 130, 215, 0.3);
  /* box-shadow: 0px 4px 4px 0px rgba(7, 29, 106, 0.4); */
  /* background: #f5f5f5; Light background to match the image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.t-hero-bg-image {
  object-fit: contain;
  width: 600px;
  height: 800px;
  /* border-radius: 10px; Rounded edges for the image */
}

.trinig-hero-head {
  color: #ffb700; /* Updated to match the yellow text color */
  font-family: "Rubik", sans-serif;
  font-size: 3rem; /* Adjusted for better visibility */
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 3px;
  /* white-space: pre-wrap; */
}

.t-hero-btn {
  margin-top: 2em;
  width: 30%; /* Wider button for better click area */
  transition: transform 0.3s ease-in-out;
}

.t-hero-btn:hover {
  transform: scale(1.1);
}

.training-hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.training-hero-text {
  flex: 1;
  padding: 20px;
}

.navigation-buttons {
  margin-top: 8em;
  display: flex;
  gap: 20px;
  width: 13%;
}

.t-nav-btn {
  cursor: pointer;
  font-size: 30px;
  padding: 15px 25px;
  border-radius: 50%;
  background-color: #071d6a;
  color: #ffffff;
}

.t-nav-btn:active {
  transform: scale(1.1);
  color: #fac17a;
  background-color: #ffffff;
}
@media (max-width: 600px) {
  .training-hero {
    padding: 20px;
    margin: 2rem 1rem;
    border-radius: 20px;
    height: auto;
    background: rgba(107, 130, 215, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack content vertically for mobile */
  }

  .t-hero {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .t-hero-bg-image {
    object-fit: contain;
    width: 100%;
    height: auto; /* Make image responsive */
    max-width: 350px; /* Limit size for mobile */
    margin-bottom: 20px; /* Add spacing between image and text */
  }

  .trinig-hero-head {
    color: #ffb700;
    font-family: "Rubik", sans-serif;
    font-size: 1.2rem; /* Reduce font size for mobile */
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-align: center; /* Center the text */
  }

  .t-hero-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
    width: 80%; /* Full-width button for mobile */
  }

  .navigation-buttons {
    margin-top: 2em;
    display: flex;
    gap: 10px;
    width: 30%; /* Wider area for navigation buttons */
    justify-content: space-around; /* Space out the buttons */
  }

  .t-nav-btn {
    font-size: 20px; /* Smaller button size for mobile */
    padding: 10px 20px;
  }
}
