/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */
/* navigation */
.details-top-con {
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  width: 40px;
}
.details-back-btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgba(227, 225, 225, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.details-btn-hero {
  padding: 10px;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}
.details-btn-hero:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.details-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  object-fit: contain;
}

.softservice-main {
  padding-left: 180px;
  padding-right: 180px;
  color: #ffffff;
}

.page-head {
  font-family: "Rubik";
  margin: 0;
  padding-top: 1em;
  text-align: center;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: 700;
}

.page-head-details {
  text-align: center;
  font-family: "Rubik";
  font-size: 1.2rem;
  font-weight: 500;
}
.details-top-data-container {
  padding: 0 50px;
}

.top-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  gap: 50px;
  padding: 40px 40px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-banner {
  width: 100%;
  height: auto;
}

.img-con {
  width: 100%;
  margin-bottom: 20px;
}

.img-con h3 {
  font-family: "Rubik";
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #071d6a;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
}

.img-con p {
  font-family: "Rubik";
  letter-spacing: 1px;
  color: #333;
  font-size: 1.1rem;
  line-height: 25px;
  margin-bottom: 2em;
}

.text-con {
  margin-bottom: 20px;
  text-align: center;
}

.info-con {
  padding: 0 20px;
  margin-top: 4em;
  text-align: center;
}

.info-con h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.info-con p {
  font-size: 1.2rem;
  color: #333333;
}

.category,
.tools {
  margin-top: 10px;
  margin-bottom: 50px;
}

.category h3,
.tools h3 {
  margin-bottom: 5px;
  font-size: 1.5rem;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}

.category p,
.tools p {
  font-size: 1.1em;
  font-family: "Rubik";
  letter-spacing: 2px;
  color: #333333;
}

.tool-item {
  margin-right: 10px;
  font-size: 1.1rem;
  font-family: "Rubik";
  letter-spacing: 2px;
  color: #333333;
}

.procedure {
  /* padding: 0 20px; */
  margin-bottom: 50px;
}

.procedure-section {
  margin: 0 0;
  /* text-align: center; */
}

.benefits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: rgba(240, 240, 240, 0.5);
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  margin-bottom: 4em;
  gap: 60px;
}
.details-soft-image {
  width: 600px;
  height: 400px;
  object-fit: cover;
}

.procedure-title {
  font-family: "Rubik";
  font-size: 2.5rem;
  margin-top: 4em;
  margin-bottom: 2em;
  color: #071d6a;
  text-align: center;
  text-shadow: 0px 5.975px 5.975px rgba(255, 177, 0, 0.25);
}

.procedure-subtitle {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 15px;
  margin-bottom: 2em;
  font-family: "Rubik";
  color: #071d6a;
  text-shadow: 0px 5.975px 5.975px rgba(255, 177, 0, 0.25);
}

.procedure-content {
  margin-bottom: 20px;
}

.procedure-item {
  margin-bottom: 10px;
}

.procedure-item-title {
  font-weight: bold;
  font-family: "Rubik";
  font-size: 1.2rem;
  color: #071d6a;
  margin-top: 1.5em;
  text-align: left;
}

.procedure-item-desc {
  font-family: "Rubik";
  letter-spacing: 1px;
  color: #333333;
  margin-top: 0.3em;
  font-size: 20px;
  text-align: left;
}
.no-procedure {
  text-align: center;
  font-weight: 600;
  font-family: "Rubik";
  color: #333333;
}
/* button section */
.details-application {
  display: flex;
  justify-content: center;
  gap: 8em;
}
.details-page-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 4em;
}

.click-btn {
  width: 400%;
  border-radius: none;
  transition: transform 0.8s ease-in-out;
}
.click-btn:hover {
  transform: scale(1.1);
}
.details-page-btn-two {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 4em;
}

.click-btn-two {
  width: 400%;
  border-radius: none;
  transition: transform 0.8s ease-in-out;
}
.click-btn-two:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .softservice-main {
    padding-left: 40px;
    padding-right: 40px;
  }

  .page-head {
    font-size: 3rem;
  }

  .top-data-container {
    padding: 0 20px;
  }

  .info-con {
    padding: 0 20px;
  }

  .procedure {
    padding: 0 20px;
  }

  .fees-section {
    padding: 10px;
  }

  .fees-card {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 600px) {
  .details-top-con {
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 40px;
  }
  .details-top-data-container {
    padding: 0 20px;
  }
  .page-head {
    font-size: 2.5rem;
  }
  .benefits {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background: rgba(240, 240, 240, 0.5);
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
    margin-bottom: 4em;
    gap: 60px;
  }

  .top-data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding: 0 20px;
    gap: 50px;
  }
  .info-con {
    padding: 0 0 !important;
    margin-top: 4em;
    text-align: center;
  }
  .info-con p {
    font-size: 15px;
    color: #333333;
  }

  .img-con h3 {
    font-family: "Rubik";
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: #071d6a;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  }
  .img-con p {
    font-family: "Rubik";
    letter-spacing: 1px;
    color: #333;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 2em;
    text-align: justify;
  }
  .category h3,
  .tools h3 {
    margin-bottom: 5px;
    font-size: 1.5rem;
    color: #071d6a;
    text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
    font-family: "Rubik";
  }

  .category p,
  .tools p {
    font-size: 15px;
    font-family: "Rubik";
    letter-spacing: 2px;
    color: #333333;
  }

  .tool-item {
    margin-right: 10px;
    font-size: 15px;
    font-family: "Rubik";
    letter-spacing: 2px;
    color: #333333;
    word-wrap: break-word;
  }

  .info-con {
    padding: 0 10px;
  }

  .procedure {
    padding: 0 10px;
  }
  /* procedure */
  .det-image {
    display: none;
  }
  .details-soft-image {
    display: none;
    /* width: 600px;
    height: 400px;
    object-fit: cover; */
  }
  .procedure-title {
    font-family: "Rubik";
    font-size: 1.5rem;
    margin-top: 4em;
    margin-bottom: 2em;
    color: #071d6a;
    text-align: center;
    text-shadow: 0px 5.975px 5.975px rgba(255, 177, 0, 0.25);
  }

  .procedure-subtitle {
    /* display: flex;
    justify-content: center; */
    text-align: center;
    font-size: 1.3rem;
    margin-top: 15px;
    margin-bottom: 2em;
    font-family: "Rubik";
    color: #071d6a;
    text-shadow: 0px 5.975px 5.975px rgba(255, 177, 0, 0.25);
  }

  .procedure-content {
    margin-bottom: 20px;
  }

  .procedure-item {
    margin-bottom: 10px;
  }

  .procedure-item-title {
    font-weight: bold;
    font-family: "Rubik";
    font-size: 1.2rem;
    color: #071d6a;
    margin-top: 1.5em;
    text-align: center;
  }

  .procedure-item-desc {
    font-family: "Rubik";
    letter-spacing: 1px;
    color: #333333;
    margin-top: 0.3em;
    font-size: 15px;
    text-align: justify;
  }
  .no-procedure {
    text-align: center;
    font-weight: 600;
    font-family: "Rubik";
    color: #333333;
    font-size: 15px;
  }
  /* button section */
  .details-application {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 1em;
    margin-bottom: 4em;
    margin-right: 70px;
  }
  .details-page-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .click-btn {
    /* width: 60%; */
    border-radius: none;
    transition: transform 0.8s ease-in-out;
  }
  .click-btn:hover {
    transform: scale(1.1);
  }
  .details-page-btn-two {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .click-btn-two {
    /* width: 60%; */
    border-radius: none;
    transition: transform 0.8s ease-in-out;
  }
  .click-btn-two:hover {
    transform: scale(1.1);
  }
}
