.captcha-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  width: fit-content;
  background-color: #f9f9f9;
}

.captcha-checkbox {
  margin-right: 10px;
  padding: 5px;
}

.captcha-label {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #071d6a;
  font-weight: 600;
}

.captcha-checkmark {
  color: green;
  font-weight: bold;
}

.attend {
  color: #071d6a;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
