.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 500px;
    width: 100%;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-title {
    font-size: 4rem;
    color: #333333;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    color: #666666;
    margin-bottom: 1rem;
  }
  
  .not-found-description {
    font-size: 1rem;
    color: #888888;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 0.6rem 1.2rem;
    background-color: #333333;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #555555;
  }
  
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 3rem;
    }
  
    .not-found-message {
      font-size: 1.2rem;
    }
  
    .not-found-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-title {
      font-size: 2.5rem;
    }
  
    .not-found-message {
      font-size: 1rem;
    }
  
    .not-found-description {
      font-size: 0.8rem;
    }
  }