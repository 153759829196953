/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.details-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  object-fit: contain;
}
.tprogramservice-main {
  padding-left: 180px;
  padding-right: 180px;
}

.page-header {
  font-family: "Rubik";
  margin: 0;
  padding-top: 1em;
  text-align: center;
  text-transform: uppercase;
  font-size: 4.5rem;
  /* color: #ffffff; */
  font-weight: 700;
}
.page-header-details {
  text-align: center;
  font-family: "Rubik";
  /* color: #ffffff; */
  font-size: 1.2rem;
  font-weight: 500;
}
