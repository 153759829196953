.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  position: relative;
}

.card__image-container {
  position: relative;
}

.card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card__tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fff;
  color: #000;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.card__content {
  padding: 16px;
  /* color: red; */
}
.general-heading-card {
  margin-bottom: 1em;
  /* line-height: 1rem; */
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Rubik";
  text-align: center;
}
.title-card {
}

.card__title {
  font-size: 20px;
  margin-bottom: 8px;
}

.card__subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.card__body {
  margin-bottom: 16px;
}

.card__actions {
  display: flex;
  justify-content: center;
  /* margin-top: 0.5rem; */
}
/* 
.paragraph {
  font-size: 16px !important;
} */
@media (max-width: 768px) {
  .card {
    max-width: 100%;
  }
}
