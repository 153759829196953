.products-banner {
  padding: 100px;
}

.our-catalog-header {
  font-family: "Rubik", sans-serif;
  font-size: 40px;
  margin-bottom: 2rem;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
}
.dev-header {
  font-family: "Rubik", sans-serif;
  font-size: 4rem;
  margin-bottom: 30px;
  color: #071d6a;
}

.product-pp {
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;
  font-size: 22px;
  color: #333;
}
.product-p {
  font-size: 1.2rem;
  line-height: 20px;
  color: #333;
  font-weight: 400;
  font-family: "Rubik";
  text-align: justify;
  margin-bottom: 20px;
}

/* Products Banner */
.products-banner {
  width: 100%;
  height: 100vh;
  display: block;
  background: rgba(255, 177, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25),
    74.467px -74.467px 74.467px 0px rgba(194, 135, 0, 0.1) inset,
    -74.467px 74.467px 74.467px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(74.46666717529297px);
  /* margin-bottom: 80px; */
}

.products-banner-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* gap: 20%; */
  height: 500px;
}

.dev-banner-desc {
  width: 80%;
  height: auto;
  position: relative;
  bottom: 7rem;
}

.dev-cart {
  width: 650px;
  height: 620px;
}

/* dev body */
.dev-body {
  padding: 50px 100px;
}

/* CardList.css */

.dev-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  padding: 20px;
}

.dev-card {
  max-width: 300px; /* Set a maximum width */
  background-color: #eeeef2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.dev-card:hover {
  transform: translateY(-5px);
}

.dev-card h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: #071d6a;
}

.dev-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.dev-card button {
  background-color: #f39c12;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s ease-in-out;
}

.dev-card button:hover {
  background-color: #e67e22;
  transform: scale(1.1);
}
/* filter section */

.search-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  /* margin: 0 auto;  removed fixed maegin*/
  margin-bottom: 20px;
}

.search-bar {
  padding: 15px 20px; /* Vertical padding and horizontal padding on the right */
  padding-left: 40px; /* Extra padding on the left for the icon */
  width: 100%; /* Full width */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  font-size: 1rem;
  background-color: #e9e9ef;
}

.search-icon {
  font-size: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa; /* Icon color */
}

/* Mobile responsiveness for screens with a max-width of 600px */
@media only screen and (max-width: 600px) {
  .products-banner {
    padding: 50px;
    height: auto; /* Adjust to fit content */
  }

  .products-banner-inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto; /* Adjust height for mobile */
    text-align: center;
  }

  .dev-banner-desc {
    width: 100%; /* Make the description take full width */
    bottom: 0; /* Remove the large bottom positioning */
    margin-top: 25%;
  }

  .dev-header {
    font-size: 2rem; /* Adjust heading size */
  }

  .product-pp {
    font-size: 15px; /* Adjust paragraph size */
  }

  .dev-cart {
    width: 100%; /* Make image responsive */
    height: auto;
    max-width: 300px; /* Limit maximum width */
  }

  /* Dev body */
  .dev-body {
    padding: 20px; /* Reduce padding on smaller screens */
  }

  /* Card grid */
  .dev-card-grid {
    grid-template-columns: 1fr; /* Make cards full-width on mobile */
    gap: 20px; /* Reduce gap between cards */
  }

  .dev-card {
    max-width: 100%; /* Allow cards to take full width */
  }

  /* Search bar */
  .search-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .search-bar {
    font-size: 0.9rem; /* Adjust font size for search input */
    padding: 20px 40px;
  }

  .search-icon {
    font-size: 16px;
  }
}
