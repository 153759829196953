.training-approach-listtt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  list-style: none;
  gap: 3%;
  padding: 60px 20px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 600px) {
  .training-approach-listtt {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    /* align-items: center; */
    list-style: none;
    gap: 3%;
    padding: 40px 20px;
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.5);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .training-approach-mains {
    margin-bottom: 50px;
    margin-top: 5rem;
  }
}
