.training-full-screen-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.prev-button {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.next-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.training-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh;
  margin-bottom: 3em;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.training-home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
}

.training-home-content {
  text-align: center;
  padding: 0 20px;
}

.training-large-centered-heading {
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: "Rubik";
}

.training-smaller-centered-heading {
  margin-top: 1em;
  font-size: 1.5rem;
  word-spacing: 1px;
  font-family: "Rubik";
}

.training-main-content-section {
  padding: 3rem 2rem;
}

.training-centered-text-section {
  flex: 1 1 400px;
  text-align: center;
  max-width: 600px;
}

.training-section-heading {
  margin-bottom: 1em;
  line-height: 1.5;
  font-family: "Rubik";
}

.training-section-details {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Rubik";
  text-align: left;
}

.training-rounded-image {
  border-radius: 10px;
  max-width: 100%;
  /* height: 100%; */
}

.training-boxed-image {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  margin-bottom: 1rem;
}

/* Approach Section */
.training-approach-main-one {
  /* margin-bottom: 50px; */
  margin-top: 1em;
  margin-bottom: 6em;
}

.training-approach-head {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1em;
  font-family: "Rubik";
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
}
.training-approach-heads {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2em;
  font-family: "Rubik";
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
}
.service-card-containers {
  display: flex;
  justify-content: center;
  gap: 20px;
}
/* technologies and tools */
.training-approach-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  gap: 3%;
}

.training-approach-listss {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  gap: 5%;
  padding: 70px 0;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.training-approach-item {
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1rem;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  flex-basis: calc(23% - 2%);
  color: #333;
}

.training-approach-item:hover {
  scale: 1.05;
}

.training-approach-item h2 {
  margin-bottom: 20px;
  font-family: "Rubik";
  transition: color 0.3s ease;
}

.training-approach-item p {
  font-family: "Rubik";
  font-size: 1rem;
  margin-bottom: 1em;
}

/* Featured Training Programs Section */
.training-programs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  gap: 2%;
}

.training-program-item {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  flex-basis: calc(23% - 2%);
}
.training-programs-list .training-program-item a {
  text-decoration: none;
}

.icon-container {
  display: flex;
  margin-top: 2em;
  /* justify-content: center; */
  /* margin-bottom: 1rem; */
}

.icon {
  font-size: 3rem;
  margin-bottom: 0.5em;
  text-align: left;
  color: #071d69;
}

.training-program-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.training-program-item h3 {
  margin-bottom: 10px;
  font-family: "Rubik";
}

.training-program-item p {
  font-family: "Rubik";
  font-size: 1rem;
  margin-bottom: 1em;
}
.technologies {
  margin-bottom: 50px;
  padding: 0 20px;
}
.tech-tools {
  margin-top: 3em;
  margin-bottom: 3em;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .training-large-centered-heading {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }

  .training-main-content-section {
    padding: 20px;
  }

  .training-smaller-centered-heading {
    display: none;
  }

  .training-section-heading {
    font-size: 1.2rem;
  }

  .training-section-details {
    font-size: 0.9rem;
  }

  .training-boxed-image {
    max-width: 400px;
  }

  .training-approach-main {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    flex-direction: column;
  }

  .training-approach-list,
  .training-programs-list {
    flex-direction: column;
    width: auto;
  }

  .training-approach-item,
  .training-program-item {
    flex-basis: 100%;
  }
  .next-button,
  .prev-button {
    display: none;
  }

  .technologies {
    margin-bottom: 50px;
    margin-top: 2rem;
    padding: 0 20px;
  }
}
@media (max-width: 600px) {
  .service-card-containers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .technologies {
    margin-bottom: 50px;
    margin-top: 0;
    padding: 0 20px;
  }
  .training-approach-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    list-style: none;
    gap: 3%;
  }
  .training-approach-item {
    margin-bottom: 20px;
    padding: 0;
    font-size: 1rem;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
    flex-basis: calc(23% - 2%);
    color: #333;
  }
  .training-approach-listss {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 5%;
    padding: 5px 20px;
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.5);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .tools-paragraph {
    font-size: 1rem;
  }
  .training-approach-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .training-main-content-section {
    padding: 3rem 20px;
  }
  .training-approach-main-one {
    margin-top: 0;
  }
  .icon {
    font-size: 2rem;
    margin-bottom: 0.5em;
    text-align: left;
    color: #071d69;
  }
}
