/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

/* Global Styles */
.products-banner,
.our-catalog,
.choose-us-product {
  padding: 100px;
}

.our-catalog-header {
  font-family: "Rubik", sans-serif;
  font-size: 40px;
  margin-bottom: 30px;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
}
.products-header {
  font-family: "Rubik", sans-serif;
  font-size: 40px;
  margin-bottom: 30px;
  color: #ffb100;
}

.product-pp {
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;
  font-size: 22px;
  color: #071d6a;
}
.product-p {
  font-size: 1.2rem;
  line-height: 20px;
  color: #333;
  font-weight: 400;
  font-family: "Rubik";
  text-align: justify;
  margin-bottom: 20px;
}

/* Products Banner */
.products-banner {
  width: 100%;
  height: 100vh;
  /* display: block; */

  background: rgba(255, 177, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25),
    74.467px -74.467px 74.467px 0px rgba(194, 135, 0, 0.1) inset,
    -74.467px 74.467px 74.467px 0px rgba(255, 255, 255, 0.1) inset;
  /* backdrop-filter: blur(74.46666717529297px); */
  /* margin-bottom: 80px; */
}

.products-banner-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* gap: 20%; */
  height: 500px;
}

.products-banner-desc {
  height: auto;
  position: relative;
  bottom: 10rem;
}

.product-cart {
  width: 580px;
  height: 480px;
}

.product-bottom-banner {
  background-color: #071d6a;
  width: 100%;
  height: 130px;
  padding: 12px;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.product-bottom-list {
  border-right: 1.8px solid #ffffff;
  height: auto;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scroll 15s linear infinite;
}

.product-bottom-icon {
  width: 40px;
  height: 40px;
  color: #ffffff;
}

.product-bottom-inner {
  width: 80%;
}

.product-bottom-title {
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

/* Our Catalog */
.our-catalog-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card {
  margin: 10px;
  flex: 1 1 300px; /* Adjust based on your card width */
}

/* Choose Us */
.choose-us-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our-catalog-icon {
  width: 580px;
  height: 400px;
}

.our-catalog-right {
  width: 50%;
}
.our-catalog-header {
  font-family: "Rubik";
  margin-top: 50px;
}
/* PRODUCT FILTER */
.search {
  /* margin-bottom: 0;
  display: flex;
  flex-direction: column; */
  width: 0%;
  /* padding: 20px;s */
  /* background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.product-name {
  width: 400px;
  padding: 10px 15px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

.product-name:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}
/* END OF INPUT */

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  margin: 5px 0;
}

.link {
  text-decoration: none;
  color: inherit;
  /* color: #000000; */
  transition: color 0.3s ease;
}

.link-title {
  display: block;
  color: #000000;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  overflow-y: auto;
}
/* .link-title::-webkit-scrollbar {
  display: none;
} */
.link-title:hover {
  color: gray;
  padding: 10px 0;
  margin: 5px 0;
  border-radius: 4px;
  background-color: #ffffff;
  /* transition: background-color 0.3s ease; */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .products-banner,
  .our-catalog,
  .choose-us-product {
    padding: 20px;
  }

  .products-banner {
    height: auto; /* Adjust height for smaller screens */
    padding: 50px 20px; /* Adjust padding */
  }

  .products-banner-inner {
    /* flex-direction: column;
    gap: 30px;
    height: auto; */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items */
    height: auto; /* Adjust height */
  }

  .products-banner-desc {
    width: 100%;
    text-align: center;
  }

  .product-cart {
    width: 300px;
    height: 300px;
  }

  .product-bottom-banner {
    height: auto;
  }

  .product-bottom-list {
    border-right: none;
    border-bottom: 1.8px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .our-catalog-map {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    .products-banner {
      width: 100%;
      height: 50vh !important;
      /* display: block; */
      background: rgba(255, 177, 0, 0.1);
      box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25),
        74.467px -74.467px 74.467px 0px rgba(194, 135, 0, 0.1) inset,
        -74.467px 74.467px 74.467px 0px rgba(255, 255, 255, 0.1) inset;
      backdrop-filter: blur(74.46666717529297px);
      /* margin-bottom: 80px; */
    }

    .products-banner,
    .our-catalog,
    .choose-us-product {
      padding: 20px;
    }
    .banner-icon {
      display: none;
    }
    .products-header {
      font-family: "Rubik", sans-serif;
      font-size: 1.8rem;
      margin-bottom: 10px;
      color: #ffb100;
    }
    .products-banner-desc {
      margin-top: 1em;
      height: 0;
      /* position: absolute; */
      margin-top: 30%;
      bottom: 0;
    }
    .product-pp {
      font-family: "Rubik", sans-serif;
      letter-spacing: 2px;
      font-size: 1.2rem;
      color: #071d6a;
    }
    /* Scroll icon */
    .product-bottom-list {
      border-right: 1.8px solid #ffffff;
      height: auto;
      width: 200px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      /* gap: 20px; */
      animation: scroll 15s linear infinite;
    }
    .product-p {
      margin-bottom: 20px;
    }
    .choose-us-product {
      flex-direction: column;
    }

    .our-catalog-header {
      font-size: 30px;
      text-align: center;
    }

    .our-catalog-icon {
      width: 200px;
      height: 200px;
    }

    .our-catalog-right {
      padding: 20px;
    }
  }
  /* BANNER ICON */
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .product-bottom-banner {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    white-space: nowrap;
    gap: 10px;
  }

  .product-bottom-list {
    display: inline-block;
    margin-right: 20px;
    animation: scroll 10s linear infinite;
  }
  .margin-btm {
    padding: 0 20px;
  }

  /* PRODUCT FILTER */
  .search {
    width: 100%;
  }

  /* INPUT SECTION */
  .product-name {
    width: 100%;
    padding: 15px 15px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    font-size: 16px;
  }
}
