/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans"); */
/* @import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.footer-main {
  background-color: #071d6a;
}

.footer-inner {
  padding: 25px 100px;
}

.social-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.social-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  font-family: "Rubik";
  letter-spacing: 0.1em;
}

.rule {
  border-top: 2px solid rgb(218, 214, 214);
}
/* LOGO */
.footer-logo-container {
  display: flex;
  gap: 20px;
  padding-top: 2em;
}

.logo-size {
  color: #fff;
  width: 22px;
  height: 22px;
}

.twitter-icon,
.linkedin-icon,
.instagram-icon,
.whatsapp-icon,
.email-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px;
  width: 30px;
  height: 30px; */
}

.twitter-icon {
  border-radius: 10%;
  /* background-color: #000; */
}

.linkedin-icon {
  border-radius: 10%;
  /* background-color: #5764ef; */
}

.instagram-icon {
  border-radius: 10%;
  /* background-color: #ef4444; */
}

.whatsapp-icon {
  border-radius: 10%;
  /* background-color: #25d366; */
}

.email-icon {
  border-radius: 10%;
  /* background-color: #ea4335; */
}
/* END OF LOGO */

.subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0.1em;
  font-size: 1.5rem;
  color: #fff;
  font-family: "Rubik";
  margin-bottom: 0.5em;
}
.connect-subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb100;
  font-weight: 500;
  font-size: 0.9rem;
}

/* FORM SECTION */
.footer-form {
  display: flex;
  margin-top: 20px;
  margin-bottom: 1.5em;
  gap: 25px;
}

.form-input {
  width: 150%;
  color: #fff !important;
}
.signup-btn {
  width: 90% !important;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}
.signup-btn:hover {
  transform: scale(1.1);
}

/* END OF FORM */

/* LAST FOOTER SECTION */
.ruled {
  background-color: #2145c8;
}
.footer-buttom {
  display: flex;
  justify-content: space-between;
  /* margin-top: 1.5em; */
  margin-bottom: 1.5em;
  font-weight: 500;
  font-size: medium;
  color: #5d6584;
  font-family: "Rubik";
  /* border-top: 2px solid burlywood; */
}

.company-link {
}

.color-change {
  text-decoration: none;
  color: #5d6584;
}
.policy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: "Rubik";
  color: #fff;
}

@media screen and (max-width: 992px) {
  .footer-inner {
    padding: 20px 50px;
  }

  .social-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo-container {
    gap: 15px;
    padding-top: 1.5em;
  }

  .footer-buttom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .footer-inner {
    padding: 15px 20px;
  }

  .social-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .social-heading {
    display: none;
  }

  .footer-logo-container {
    flex-direction: row;
    gap: 20px;
    padding-top: 1em;
  }

  .subscribe {
    font-size: 1.2rem;
    margin-top: 30px;
  }

  .connect-subscribe {
    font-size: 0.8rem;
  }

  .footer-form {
    flex-direction: column;
    gap: 15px;
  }

  .form-input {
    width: 100%;
  }

  .signup-btn {
    width: 100% !important;
  }

  .footer-buttom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .policy {
    font-size: 0.8rem;
  }
}
