.overview-company-logo-container {
  padding-left: 30px;
  border-bottom: 2px solid rgba(51, 51, 51, 0.3);
}
.overview-company-logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
}
.overview-web-hero-container {
  padding-top: 35px;
  margin-bottom: 100px;
  margin-top: 0;
}
.overview-hero-con {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  padding: 15px 20px;
  /* border-radius: 10px;
  border: 1px solid rgba(51, 51, 51, 0.2); */
  background: rgba(240, 240, 240, 0.5);
}

.overview-top-con {
  height: 90px;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  width: 40px;
}
.overview-back-btn-icon {
  color: #071d6a;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgba(227, 225, 225, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.overview-btn-hero {
  padding: 10px;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}
.overview-btn-hero:hover {
  cursor: pointer;
  transform: scale(1.1);
}
/* SECTION TWO */
.overview-heading {
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5em;
}
.overview-heading-details {
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* button */
.over-btn-container {
  margin-top: 4em;
  display: flex;
  gap: 10em;
}
.overview-div-btn {
  width: 30%;
  margin-top: 30px;
  transition: transform 0.3s ease-in-out;
}
.overview-div-btn:hover {
  transform: scale(1.1);
}
.web-overview-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.overview-img {
  max-width: 600px;
  margin-top: 0;
}
