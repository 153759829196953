.service-card-container-benefit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  place-items: center;
  padding-top: 1rem;
  list-style: none;
  padding: 30px;
  border-radius: 8px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.training-approach-main-benefits {
  padding: 100px 0;
}

@media screen and (max-width: 600px) {
  .training-approach-head {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1em;
    font-family: "Rubik";
    color: #071d6a;
    text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  }
}
