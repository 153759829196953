.privacy-main {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 2em;
}

.privacy-section {
  margin-bottom: 2rem;
}

.privacy-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.8em;
}

.privacy-details {
  text-align: center;
}

.privacy-heading {
  margin-bottom: 1.5rem;
  line-height: 10px;
  font-size: 35px !important;
  font-weight: 600;
  font-family: "Rubik";
}

.privacy-description {
  font-size: 1.2rem;
  font-weight: 200;
  font-family: "Rubik";
  text-align: justify;
  margin-top: 1.5rem;
}

.privacy-sections {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.privacy-subsection-one {
  padding: 20px 30px;
  border-radius: 5px;
  text-align: left;
  border: 1px solid rgb(215, 210, 210);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.privacy-subsection-two {
  padding: 20px 30px;
  border-radius: 5px;
  text-align: left;
  border: 1px solid rgb(215, 210, 210);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-subsection-heading {
  margin-bottom: 1em;
  margin-top: 2em;
  line-height: 10px;
  font-size: 1.5rem;
  font-family: "Rubik";
}
.privacy-subsection-heading-cookies {
  margin-bottom: 1em;
  margin-top: 2em;
  line-height: 10px;
  font-size: 1.5rem;
  font-family: "Rubik";
}
.privacy-subsection-subheading {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Rubik";
  margin-bottom: 0.5rem;
}

.privacy-subsection-content {
  font-size: 1.2rem;
  font-weight: 200;
  font-family: "Rubik";
  text-align: left;
  margin-bottom: 1rem;
}
.privacy-btn {
  width: 18%;
}

/* Responsive styles */
/* Tablet */
@media screen and (max-width: 992px) {
  .privacy-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .privacy-section {
    margin-bottom: 30px;
  }

  .privacy-content {
    flex-direction: column;
    align-items: center;
    padding-top: 1em;
  }

  .privacy-details {
    padding: 30px 0;
    text-align: center;
  }

  /* .privacy-heading {
    font-size: 28px;
  }

  .privacy-description {
    font-size: 1rem;
  }

  .privacy-subsection-heading {
    font-size: 1;
  }

  .privacy-subsection-subheading {
    font-size: 1.2rem;
  }

  .privacy-subsection-content {
    font-size: 1rem;
  } */
}

/* Mobile */
@media screen and (max-width: 600px) {
  .privacy-main {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 1em;
  }
  .privacy-subsection-one {
    padding: 20px 15px;
    border-radius: 5px;
    text-align: left;
    border: 1px solid rgb(215, 210, 210);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .privacy-subsection-two {
    padding: 10px 15px;
    border-radius: 5px;
    text-align: left;
    border: 1px solid rgb(215, 210, 210);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .privacy-section {
    margin-bottom: 20px;
  }

  .image-sized {
    width: 300px;
    height: 150px;
  }

  .privacy-details {
    padding: 20px 0;
  }

  .privacy-heading {
    font-size: 24px;
  }

  .privacy-description {
    font-size: 0.9rem;
  }

  .privacy-subsection-heading {
    font-size: 1.5rem;
  }
  .privacy-subsection-heading-cookies {
    font-size: 1.3rem;
    margin-bottom: 1em;
    margin-top: 2em;
    line-height: 10px;
    font-family: "Rubik";
  }

  .privacy-subsection-subheading {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5em;
    margin-top: 2em;
  }

  .privacy-subsection-content {
    font-size: 0.8rem;
    text-align: justify;
  }
  .privacy-btn {
    width: 35%;
  }
}
