.global-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  outline: none;
  padding: 10px 25px;
  flex-shrink: 0;
  cursor: pointer;
  font-family: "Playpen";
  letter-spacing: 2px;
}

.button-icon {
  margin-right: 8px; /* Adjust based on your design */
}
