@import url("https://fonts.googleapis.com/css2?family=Inter");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans");

.input:focus {
  border: 2px solid #479be8;
  outline: none !important;
}
/* .input-container {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
} */

@media only screen and (max-width: 600px) {
  .inputContact {
    width: 100%;
    border: 0.5px solid gray;
    padding-left: 15px;
  }
}
