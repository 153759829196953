.nav-link-container {
  margin-top: 7px;
  display: flex;
  justify-content: center;
}
.wrapper-fluid {
  background-color: #13191C;
}

.dark-mode {
  color: #282a94;
  width: 20px;
  height: 20px;
}
.menu-button {
  width: 20px;
  height: 20px;
  display: none;
}

.version {
  background-color: #864733;
  padding: 3px 10px;
  font-size: 13px;
  margin-right: 45px;
  color: #fff;
  font-weight: 500;
}
.drop-down-links {
  position: absolute;
  right: 100px;
  top: 62px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 2px;
  text-decoration: none;
  background-color: #000000;
  padding: 12px;
  font-family: "Rubik";
  z-index: 100;
}
.drop-down-links:hover {
  transition: 0.8s;
  color: #864733 !important;
}

.nav-list {
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Rubik";
}

.nav-list:hover {
  transition: 0.8s;
  color: #864733 !important;
}
.list-container {
  list-style: none;
  margin-bottom: 15px;
  color: whitesmoke;
}

.list-container:hover {
  color: #282a94 !important;
  transition: 0.8s;
}

.nav-main {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
  height: 70px;
  padding: 10px 100px;
  background-color: transparent;
}

.list {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}

.mobile-x {
  float: right;
  width: 40px;
  height: 40px;
  color: #00ffff;
}
.mobile-nav {
  position: absolute;
  background-color: #000000;
  right: 0px;
  padding: 25px;
  width: 100%;
  height: auto;
  top: 0;
  justify-content: center;
  transition: 10s;
  z-index: 1000;
}

.mobile-nav ul {
  margin: 60px 10px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .nav-main {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .menu-button {
    display: block;
  }
  .list {
    display: none;
  }
  .nav-link-container {
    display: flex;
  }
  .icons-right {
    display: flex;
    gap: 30px;
    align-items: center;
    color: #282a94;
  }
}
