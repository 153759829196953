/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.droid-icons {
  padding: 4em 0;
}
.contact-header {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  color: #282a94;
  font-family: "Rubik";
}

/* IconList.css */
.icon-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  /* max-width: 200px; Set a maximum width */
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.icon-item:hover {
  transform: scale(1.05);
}

.icon-label {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-align: center;
}
.droid-icon-size {
  width: 28px;
  height: 28px;
}

/* Customize the size of the icons */
.icon {
  font-size: 20px;
  color: #2a3d66; /* Change the color to match your theme */
}

.margin-btm {
  padding: 0 100px;
}

/*Tablet:  On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .equip-main {
    padding: 0 50px;
    padding-top: 50px;
  }
}
/*mobile:  On screens that are 600px) or less, set the background color to blue */
@media screen and (max-width: 600px) {
  .equip-main {
    padding: 0 20px;
    padding-top: 50px;
  }

  .equipment-btn-details {
    width: 100%;
    border-radius: none;
    transition: transform 0.3s ease-in-out;
  }
  .our-catalog-right {
    width: 100%;
    padding: 20px;
  }
  .margin-btm {
    padding: 0 20px;
  }
}
