/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.aboutus-bg-color {
  background-color: #f9f9f9;
}
.about-main {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 2em;
}
/* SECTION ONE OUR HISTORY */
.aboutus-marginbutton {
  margin-bottom: 50px;
}
.section-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.8em;
}
.image-sized {
  width: 600px;
  height: 500px;
  object-fit: contain;
}
.about-history {
  padding: 70px 30px;
  text-align: center;

  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.about-history-heading {
  margin-bottom: 1.5em;
  line-height: 10px;
  font-size: 35px !important;
  font-weight: 700;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}
.about-histoy-details {
  font-size: 1.2rem;
  font-weight: 200;
  font-family: "Rubik";
  text-align: justify;
  line-height: 30px;
  color: #333;
}
.about-history-details-div {
  padding: 70px 30px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* VISION AND MISSION SECTION */
.vision-marginbuttom {
  padding: 70px 0;
  /* padding: 0 0; */
  border-bottom: 1px solid #071d6a;
  border-top: 1px solid #071d6a;
  /* border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  /* background-color: #ffffff;
  margin-bottom: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  flex-basis: calc(23% - 2%); */
}
.vision {
  text-align: center;
  margin-bottom: 1em;
  line-height: 10px;
  font-size: 35px !important;
  font-weight: 800;
  color: #282a94;
  font-family: "Rubik";
}

/* VISION MISSION VALUE - BOX SECTIONS */
.vision-mission-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  gap: 30px;
}
/* BOX ONE */
.vision-header {
  text-align: center;
  margin-bottom: 1.2em;
  line-height: 10px;
  font-size: 32px;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}

.vision-main {
  padding: 40px 0;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.vision-box {
  padding: 20px 20px;
}

.vision-box p {
  font-size: 1.2rem;
  font-weight: 200;
  color: #333;
  font-family: "Rubik";
  text-align: left;
  line-height: 1.5;
}
/* CORE VALUES */
.core-value-vision-main {
  margin-bottom: 100px;
  margin-top: 50px;
}
.core-value-vision-header {
  text-align: center;
  margin-bottom: 0.8em;
  line-height: 10px;
  font-size: 2.5rem;
  font-weight: 700;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
}

.core-value-vision-header-small {
  margin-bottom: 1.2em;
  line-height: 10px;
  font-size: 1.7rem;
  color: #282a94;
  font-family: "Rubik";
  margin-top: 10px;
}
.core-value-icons {
  font-size: 40px;
  font-weight: 700;
  color: #282a94;
}

.core-value-vision-box {
  padding: 40px 20px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.core-value-vision-box p {
  font-size: 1.2rem;
  font-weight: 200;
  color: #333;
  font-family: "Rubik";
  text-align: left;
  line-height: 1.5;
}
/* OUR TEAM SECTION */
.ourteam-marginbottom {
  margin-bottom: 50px;
}
.our-team {
  text-align: center;
  margin-bottom: 0.6em;
  font-family: "Rubik";
  font-size: 2rem;
}
.our-team-details {
  padding: 0 90px;
  text-align: center;
  font-family: "Rubik";
  font-size: 1.2rem;
  color: rgb(186, 184, 184);
  margin-bottom: 1.5em;
}
/* OUR TEAM PROFILE IMAGES */
.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  text-align: center;
}
.image-sizes {
  width: 250px;
  height: 290px;
  /* object-fit: cover; */
}
.profile-one h3 {
  font-family: "Rubik";
  font-size: 1.2rem;
}
.profile-one p {
  font-family: "Rubik";
  color: rgb(186, 184, 184);
  font-weight: 600;
}
.about-history-details-div {
  width: 100%;
  margin-bottom: 50px;
}

.core-values {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 20px;
}
/* Droid staff */
.team-heading {
  text-align: center;
  margin-bottom: 0.6em;
  font-family: "Rubik";
  font-size: 2rem;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-weight: 700;
} /*
.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
}

.team {
  padding: 10px 20px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 30px;
  text-align: center;
}

.team-image {
  width: 200px;
  height: 230px;
  text-align: center;
  object-fit: cover;
  border-radius: 50%;
}

.team-details {
  margin-top: 5px;
}

.team-name {
  font-size: 1.2rem;
  margin: 5px 0;
}

.team-designation {
  font-size: 1em;
  color: #777;
}

.team-socials {
  margin-top: 5px;
}

.social-link {
  margin: 0 5px;
}
.twitter {
  color: #000000;
}

.social-link {
  width: 20px;
  height: 20px;
} */

/* Tablet: On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .about-main {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 2em;
  }
  /* SECTION ONE OUR HISTORY */
  .aboutus-marginbutton {
    margin-bottom: 25px;
  }
  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.8em;
  }
  .image-sized {
    width: 370px;
    height: 200px;
    object-fit: contain;
  }
  .about-history {
    padding: 35px 0px;
    text-align: left;
  }

  .about-history-heading {
    margin-bottom: 1em;
    line-height: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #282a94;
    font-family: "Rubik";
    text-align: center;
  }
  .about-histoy-details {
    font-size: 1rem;
    font-weight: 200;
    color: rgb(186, 184, 184) !important;
    text-align: justify;
    line-height: 20px;
    font-family: "Rubik";
  }

  /* VISION AND MISSION SECTION */
  .vision-marginbuttom {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
    flex-basis: calc(23% - 2%);
  }

  /* VISION MISSION VALUE - BOX SECTIONS */
  .vision-mission-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    gap: 30px;
  }
  /* BOX ONE */
  .vision-header {
    text-align: center;
    margin-bottom: 1em;
    line-height: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #282a94;
    font-family: "Rubik";
  }

  .vision-box {
    padding: 35px 20px;
    box-shadow: 0.35em 0.35em 1em grey;
    border-radius: 5px;
  }
  .vision-box p {
    font-size: 1.2rem;
    font-weight: 200;
    color: rgb(186, 184, 184);
    font-family: "Rubik";
    text-align: left;
    line-height: 1.3;
  }
  /* CORE VALUE */
  .core-value-vision-main {
    margin-bottom: 50px;
  }
  .core-value-vision-header {
    text-align: center;
    margin-bottom: 1em;
    line-height: 10px;
    font-size: 32px;
    color: #282a94;
    font-family: "Rubik";
  }

  .core-value-vision-box {
    background-color: #ffffff;
    padding: 25px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .core-value-vision-box p {
    font-size: 1.2rem;
    font-weight: 200;
    color: rgb(186, 184, 184);
    font-family: "Rubik";
    text-align: left;
    line-height: 1.3;
  }

  /* OUR TEAM SECTION */
  .ourteam-marginbottom {
    margin-bottom: 50px;
  }
  .our-team {
    text-align: center;
    margin-bottom: 0.6em;
    font-family: "Rubik";
    font-size: 2rem;
  }
  .our-team-details {
    padding: 0 90px;
    text-align: center;
    font-family: "Rubik";
    font-size: 1.2rem;
    color: rgb(186, 184, 184);
  }
  /* OUR TEAM PROFILE IMAGES */
  .profile-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
  }
  .image-sizes {
    width: 220px;
    height: 290px;
  }

  .profile-one h3 {
    font-family: "Rubik";
    font-size: 1.2rem;
  }
  .profile-one p {
    font-family: "Rubik";
    color: rgb(186, 184, 184);
    font-weight: 600;
  }
}

/*Mobile:  On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .about-main {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 2em;
  }

  /* SECTION ONE */
  .aboutus-marginbutton {
    margin-bottom: 35px;
  }
  .section-one {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image-sized {
    width: 600px;
    height: 500px;
    object-fit: contain;
  }

  .about-history {
    padding: 15px 15px;
    padding-top: 40px;
    text-align: left;
  }

  .about-history-heading {
    margin-bottom: 1em;
    line-height: 0;
    font-size: 0.5rem;
    font-weight: 500;
    color: #282a94;
    font-family: "Rubik";
    text-align: center;
  }
  .about-histoy-details {
    font-size: 15px;
    font-weight: 200;
    color: #333 !important;
    text-align: justify;
    letter-spacing: 0;
    line-height: 20px;
    font-family: "Rubik";
  }
  .about-history-details-div {
    padding: 30px 15px;
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.5);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  /* VISION AND MISSION SECTION */
  .vision-marginbuttom {
    background-color: 0;
    margin-bottom: 40px;
    padding: 0;
    border: none;
    border-radius: 0;
    transition: 0;
    flex-basis: 0;
  }

  /* VISION MISSION VALUE - BOX SECTIONS */
  .vision-mission-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    gap: 30px;
  }
  .vision-main {
    padding: 0;
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.5);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  /* BOX ONE */
  .vision-header {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 1em;
    line-height: 0;
    font-size: 30px;
    font-weight: 600;
    color: #282a94;
    font-family: "Rubik";
  }

  /* .vision-box {
    padding: 25px 10px;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .vision-box p {
    padding: 0 5px;
    font-size: 1rem;
    font-weight: 200;
    color: rgb(186, 184, 184);
    font-family: "Rubik";
    text-align: left;
    line-height: 1.5;
  } */
  .vision-box {
    padding: 20px 15px;
  }

  .vision-box p {
    font-size: 15px;
    font-weight: 200;
    color: #333;
    font-family: "Rubik";
    text-align: justify;
    line-height: 1.5;
  }
  /* CORE VALUE */
  .core-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }
  .core-value-vision-main {
    margin-bottom: 50px;
  }
  .core-value-vision-header {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 1em;
    line-height: 10px;
    font-size: 25px;
    color: #282a94;
    font-family: "Rubik";
  }

  .core-value-vision-box {
    background-color: #ffffff;
    padding: 25px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .core-value-vision-box p {
    font-size: 1rem;
    font-weight: 200;
    color: rgb(186, 184, 184);
    font-family: "Rubik";
    text-align: left;
    line-height: 1.5;
  }

  /* OUR TEAM SECTION */
  .ourteam-marginbottom {
    margin-bottom: 50px;
  }
  .our-team {
    text-align: center;
    margin-bottom: 0.4em;
    font-family: "Rubik";
    font-size: 25px;
  }
  .our-team-details {
    padding: 0 0;
    text-align: center;
    font-family: "Rubik";
    font-size: 15px;
    color: rgb(186, 184, 184);
  }
  /* OUR TEAM PROFILE IMAGES */
  .profile-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    text-align: center;
    margin-bottom: 2em;
  }
  .image-sizes {
    width: 100%;
    height: 2%;
  }

  .profile-one h3 {
    font-family: "Rubik";
    font-size: 1.8rem;
  }
  .profile-one p {
    font-family: "Rubik";
    color: rgb(186, 184, 184);
    font-weight: 600;
    font-size: 1.2rem;
  }
  /* Droid staff */
  /* .team-heading {
    text-align: center;
    margin-bottom: 0.6em;
    font-family: "Rubik";
    font-size: 2rem;
    color: #282a94;
  }
  .team-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
  }

  .team {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    text-align: center;
  }

  .team-image {
    width: 200px;
    height: 200px;
    padding: 0;
    object-fit: contain;
  }

  .team-details {
    margin-top: 10px;
  }

  .team-name {
    font-size: 1.5em;
    margin: 10px 0;
  }

  .team-designation {
    font-size: 1em;
    color: #777;
  }

  .team-socials {
    margin-top: 10px;
  }

  .social-link {
    margin: 0 5px;
  }
  .twitter {
    color: #000000;
  }

  .social-link {
    width: 24px;
    height: 24px;
  } */
}

.team-card {
  position: relative;
  width: 100%;
  height: 400px;
  margin: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-card .content {
  position: absolute;
  padding: 8px;
  bottom: 0;
  width: 100%;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  background: linear-gradient(to top, rgba(3, 3, 3, 0.781), rgba(0, 0, 0, 0));
  box-shadow: 0 -10 10px rgba(0, 0, 0, 0.1);
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  transition: bottom 0.5s;
  transition-delay: 0.8s;
}

.team-card:hover .content {
  bottom: 0px;
  transition-delay: 0s;
}

.team-card .imgbox img {
  transform: scale(1);
  transition: 0.5s;
  max-width: 100%;
  height: auto;
}
.team-card:hover .imgbox img {
  transform: scale(1.15);
}

.team-card .content .contentBox h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bolder;
  font-size: 18px;
  /* z-index: 15; */
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  opacity: 1;
  /* transform: translate(20px, 30px);
  transition-delay: 0.6s; */
}

.team-card:hover .content .contentBox h3 {
  opacity: 0;
  transform: translateY(0px);
}

.team-card:hover .content .contentBox h3 span {
  opacity: 0;
}
.team-card .content .contentBox h3 span {
  font-size: 12px;
  font-weight: bolder;
  text-transform: initial;
  color: rgba(255, 255, 255, 0.5);
}

.social {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social li {
  list-style: none;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
}

.team-card:hover .content .social li {
  transform: translateY(0px);
  opacity: 1;
}
.social li a {
  width: 55px;
  height: 55px;
  background-color: #ffffff46;
  text-align: center;
  line-height: 55px;
  font-size: 18px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.social li a .icon-s {
  position: relative;
  color: #fff;
  transition: 0.5s;
  z-index: 3;
}

.social li a:hover .icon-s {
  color: #fff;
  transform: rotateY(360deg);
}

.social li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e18800;
  transition: 0.5s;
  z-index: 2;
}

.social li a:hover:before {
  top: 0;
  background: #e18800;
}
