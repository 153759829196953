/* @import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Playpen Sans");
@import url("https://db.onlinewebfonts.com/c/eb5b5ee332420add9a40ee988cb6ac37?family=Rubik-Regular"); */

.contact-containerr {
  padding: 0 100px;
  /* margin-bottom: 100px; */
}
.container-raduis {
  display: flex;
  gap: 20px;
  border-radius: 5px;
  padding: 40px 30px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.contact-information {
  flex: 1 1 40%;
}
.message {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.message h2 {
  margin-bottom: 0.6em;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 2rem;
  color: #071d69;
}

.message p {
  color: #333;
  font-family: "Rubik";
  font-size: 1.2em;
  margin-bottom: 4em;
}
/* CONTACT INFORMATION */
.contact-information {
  margin-top: 5em;
  background-color: #071d69;
  border-radius: 8px;
  width: auto;
  height: 50%;
}
.contact-info-details {
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 0;
  padding-bottom: 0;
}
.info-details-head {
  color: white;
  font-size: 1.8rem;
  margin-bottom: 0.6em;
  font-family: "Rubik";
}
.info-details-p {
  color: #fbcc34;
  font-size: 1.1rem;
  font-family: "Rubik";
  margin-bottom: 2.5em;
}
/* phone number */
.flex-contact {
  display: flex;
}
.call-contact {
  gap: 10px;
  padding-bottom: 1.5em;
  display: flex;
  align-items: center;
  font-style: italic;
}
.phone-icon {
  color: white;
  font-size: 1.5rem;
  margin-right: 25px;
}
.phone-no {
  color: #ffffff;
  text-decoration: none;
}

.icon-section {
  margin-top: 3em;
  margin-bottom: 3em;
  display: flex;
  gap: 25px;
}
.form-icon {
  color: #ffffff;
}
.form-icon:hover {
  cursor: pointer;
}

/* MESSAGE  SECTION */
.input-section {
  flex: 1 1 60%;
  padding: 0 10px;
  /* background-color: #282a94; */
}

/* INPUT SECTION ONE */
.name-section {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}
.input-width {
  width: 100%;
}

.input-container {
  width: 80%;
}

/* INPUT SECTION TWO */
.name-section-two {
  display: flex;
  position: relative;
  bottom: 1.2rem;
  /* justify-content: space-between; */
  /* margin-top: 10px; */
  gap: 10px;
}

.input-container {
  width: 100%;
}
.subject-feild {
  font-family: "Rubik";
}

/* MESSAGE AREA */
.text-area {
  margin-top: 20px;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  margin: 10px 0 30px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: "Rubik";
  resize: none;
}

.textarea-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 30%;
  margin-bottom: 20px;
  /* transition: transform 0.3s ease-in-out; */
}

.textarea-btn:hover {
  /* transform: scale(1.1); */
  background-color: #737458;
}
.icon-style {
  color: #ffffff;
  margin-left: 10px;
}
/* TESTIMONIAL SECTION */
.equip-margin-bt {
  margin-bottom: 10px;
  margin-top: 100px;
}
.contact-testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.contact-testimonial-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
  transition: transform 0.3s ease-in-out;
}

.contact-testimonial-btn:hover {
  transform: scale(1.1);
  background-color: #737458;
}
.icon-style {
  color: #ffffff;
  margin-left: 10px;
}
/* TESTIMONIAL FORM */
.testimonial-form {
  /* margin-top: 50px; */
  max-width: 600px;
  margin: 70px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-form .form-group {
  margin-bottom: 20px;
}

.testimonial-form input[type="text"],
.testimonial-form textarea {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.testimonial-form textarea {
  height: auto;
  resize: vertical;
}

.testimonial-form button {
  width: 100%;
  padding: 10px 0;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.testimonial-form button:hover {
  background-color: #333333;
}

.icon-style {
  margin-left: 10px;
}
.add-testimonial {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
/* FAQ section */
.faq-container {
  width: 70%;
  /* max-width: 900px; */
  margin: 100px auto;
  padding: 20px;
  margin-bottom: 3em;
}

.faq-container h1 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  width: 100%;
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #ddd;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #fafafa;
  padding: 0 10px;
}

.faq-answer.open {
  max-height: 150px;
  padding: 15px;
}

/* Tablet: On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .contact-containerr {
    padding: 0 25px;
    margin-bottom: 50px;
  }
  .container-raduis {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #282a94;
    border-radius: 5px;
  }
  .contact-information {
    flex: 1 1 40%;
  }
  .message {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 80px;
  }

  .message h2 {
    margin-bottom: 0.6em;
  }

  .message p {
    color: rgb(124, 124, 124);
    font-family: "Rubik";
    font-size: 1.2em;
  }
  /* CONTACT INFORMATION */
  .contact-information {
    /* background-color: #292929; */
    background-color: #282a94;
    width: auto;
    /* width: fit-content; */

    /* border-radius: 5px; */
  }
  .contact-info-details {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 140px;
  }
  .info-details-head {
    color: white;
    font-size: 1.8rem;
    margin-bottom: 0.6em;
    font-family: "Rubik";
  }
  .info-details-p {
    color: rgb(124, 124, 124);
    font-size: 1.1rem;
    font-family: "Rubik";
    margin-bottom: 4em;
  }
  /* phone number */
  .flex-contact {
    display: flex;
  }
  .call-contact {
    gap: 10px;
    padding-bottom: 1.5em;
    display: flex;
    align-items: center;
    font-style: italic;
  }
  .phone-icon {
    color: white;
    font-size: 1.5rem;
    margin-right: 25px;
  }
  .phone-no {
    color: #ffffff;
    text-decoration: none;
  }

  .icon-section {
    margin-top: 5em;
    display: flex;
    gap: 25px;
  }
  .form-icon {
    color: #ffffff;
  }
  .form-icon:hover {
    cursor: pointer;
  }

  /* MESSAGE  SECTION */
  .input-section {
    flex: 1 1 60%;
    padding: 10px;
    /* background-color: #282a94; */
  }

  /* INPUT SECTION ONE */
  .name-section {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
  }
  .input-width {
    width: 100%;
  }

  .input-container {
    width: 80%;
  }

  /* INPUT SECTION TWO */
  .name-section-two {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 10px;
    gap: 10px;
  }

  .input-container {
    width: 100%;
  }

  /* MESSAGE AREA */
  .text-area {
    margin-top: 20px;
    margin-bottom: 23px;
  }

  textarea {
    width: 100%;
    margin: 10px 0 30px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }

  .textarea-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 40%;
    /* transition: transform 0.3s ease-in-out; */
  }

  .textarea-btn:hover {
    /* transform: scale(1.1); */
    background-color: #737458;
  }
}

/*Mobile:  On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .contact-containerr {
    padding: 0 8px;
    margin-bottom: 50px;
  }
  .container-raduis {
    display: flex;
    flex-direction: column;
    padding: 20 10px;
    border: 1px solid #282a94;
    border-radius: 5px;
  }
  .contact-information {
    flex: 1 1 40%;
  }
  .message {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .message h2 {
    margin-bottom: 0.6em;
    text-align: center;
  }

  .message p {
    color: rgb(124, 124, 124);
    font-family: "Rubik";
    font-size: 1em;
    text-align: center;
  }
  /* CONTACT INFORMATION */
  .contact-information {
    /* background-color: #292929; */
    background-color: #282a94;
    width: auto;
    /* width: fit-content; */

    /* border-radius: 5px; */
  }
  .contact-info-details {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .info-details-head {
    color: white;
    font-size: 1.8rem;
    margin-bottom: 0.6em;
    font-family: "Rubik";
  }
  .info-details-p {
    color: rgb(124, 124, 124);
    font-size: 1.1rem;
    font-family: "Rubik";
    margin-bottom: 4em;
  }
  /* phone number */
  .flex-contact {
    display: flex;
  }
  .call-contact {
    gap: 10px;
    padding-bottom: 1.5em;
    display: flex;
    align-items: center;
    font-style: italic;
  }
  .phone-icon {
    color: white;
    font-size: 1.5rem;
    margin-right: 25px;
  }
  .phone-no {
    color: #ffffff;
    text-decoration: none;
  }

  .icon-section {
    margin-top: 5em;
    display: flex;
    gap: 25px;
  }
  .form-icon {
    color: #ffffff;
  }
  .form-icon:hover {
    cursor: pointer;
  }

  /* MESSAGE  SECTION */
  .input-section {
    flex: 1 1 60%;
    padding: 5px;
    /* background-color: #282a94; */
  }

  /* INPUT SECTION ONE */
  .name-section {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
  }
  .input-width {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
  }

  .input-container {
    width: 80%;
  }

  /* INPUT SECTION TWO */
  .name-section-two {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-top: 5px;
    gap: 5px;
  }

  .input-container {
    width: 100%;
  }

  /* MESSAGE AREA */
  .text-area {
    margin-top: 20px;
    margin-bottom: 23px;
  }

  textarea {
    width: 100%;
    margin: 10px 0 30px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }

  .textarea-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
    /* transition: transform 0.3s ease-in-out; */
  }

  .textarea-btn:hover {
    /* transform: scale(1.1); */
    background-color: #737458;
  }
  .contact-testimonial-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
    transition: transform 0.3s ease-in-out;
  }
  /* FAQ */
  .faq-container {
    width: 100%;
    /* max-width: 900px; */
    margin: 100px auto;
    padding: 20px;
    margin-bottom: 3em;
  }
  .faq-container h1 {
    font-size: 1.5rem;
  }
  .faq-question {
    width: 100%;
    padding: 10px 15px;
    font-size: 0.8rem;
  }
  .faq-answer {
    font-size: 0.8rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0 10px;
  }
}
