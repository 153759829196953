.project-company-logo-container {
  padding-left: 30px;
  border-bottom: 2px solid rgba(51, 51, 51, 0.3);
}
.project-company-logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
}
.project-web-hero-container {
  padding-top: 20px;
  margin-bottom: 100px;
  margin-top: 0;
}
.project-hero-con {
  padding: 10px 50px;
}

.project-top-con {
  height: 90px;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  width: 40px;
}
.project-back-btn-icon {
  color: #071d6a;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgba(227, 225, 225, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.project-btn-hero {
  padding: 10px;
  border: none;
  border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}
.project-btn-hero:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.web-project-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.web-project-btn {
  padding: 0 30px;
  border: none;
  border-radius: 50px;
  color: #071d6a;
  background-color: #e0e2ea;
}
.web-project-heading {
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.web-project-details {
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* card section */
.project-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  margin-top: 2em;
}

.project-card {
  background-color: rgba(240, 240, 240, 0.5);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  text-align: center;
}

.project-card-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.project-card h3 {
  margin-bottom: 10px;
  color: #071d6a;
  font-family: "Rubik";
  font-size: 21.6px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.project-card p {
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
  text-align: left;
}

/* .project-download-btn {
  background-color: #ffb703;
  color: #071d6a;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.project-download-btn:hover {
  background-color: #fb8500;
} */
.project-div-btn-cont {
  display: flex;
  justify-content: center;
}
.project-div-btn {
  width: 80%;
  margin-top: 30px;
  transition: transform 0.3s ease-in-out;
}
.project-div-btn:hover {
  transform: scale(1.1);
  background-color: #fb8500;
}
.create-websites-btn-containerss {
  display: flex;
  justify-content: center;
  margin-top: 4em;
}
.create-websites-btn-containerss:hover {
  cursor: pointer;
}
.create-websites-btnss {
  width: 25%;
  transition: transform 0.3s ease-in-out;
}
.create-websites-btnss:hover {
  transform: scale(1.1);
}
