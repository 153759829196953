/* Home to software section */
.home-to-software {
  padding-left: 100px;
  padding-right: 100px;
}
.software-banner {
  border-radius: 10px;
  background: #071d6a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.soft-banner {
  padding: 0 4em;
  padding-top: 5em;
  padding-bottom: 33em;
}

.software-approach-head {
  text-align: center;
  margin-bottom: 1.5em;
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-family: "Rubik";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.over {
  color: #f0f0f0;
  font-weight: 600;
}
.soft-paragraph {
  color: rgba(240, 240, 240, 0.8);
}

.home-to-software-p {
  font-family: "Rubik";
  font-size: 1rem;
  color: rgba(240, 240, 240, 0.8);
  margin-bottom: 1em;
}
.banner-image {
  position: absolute;
  top: 30rem;
  /* position: relative; */
  /* bottom: 30rem; */
}

/* OTHER CSS */
.home-section {
  padding-top: 1rem;
  padding-bottom: 50px;
  background-color: red;
}

.home-content {
  text-align: center;
  padding: 0 20px;
}

.full-screen-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.large-centered-heading {
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: "Rubik";
}

.smaller-centered-heading {
  margin-top: 1em;
  font-size: 1.5rem;
  word-spacing: 1px;
  color: #fff;
  font-family: "Rubik";
}

.main-content-section {
  padding: 1rem 2rem;
  margin-top: 2em;
}

.flex-row-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: 2rem;
  padding: 1.8em 0;
}

.reverse {
  flex-direction: row-reverse;
}

.centered-text-section {
  flex: 1 1 400px;
  text-align: center;
  max-width: 600px;
}

.section-heading {
  margin-bottom: 1em;
  line-height: 1.5;
  font-family: "Rubik";
}

.section-details {
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(124, 124, 124);
  font-family: "Rubik";
  text-align: left;
}

.rounded-image {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.boxed-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0.35em 0.35em 0 grey;
}
.training-approach-main {
  margin-bottom: 50px;
  margin-top: 18rem;
}
/* Approach Section */
.software-approach-main {
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 100px;
}
.software-approach-heads {
  text-align: center;
  margin-bottom: 1em;
  font-family: "Rubik";
  color: #ffb100;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.software-approach-list {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  list-style: none;
  gap: 2%;
}

.software-approach-item {
  margin-bottom: 20px;
  color: #071d6a !important;
  border-radius: 10px;
  padding: 20px 20px;
  background: rgba(7, 29, 106, 0.1);
  box-shadow: 49.467px -49.467px 49.467px 0px rgba(5, 22, 81, 0.1) inset,
    -49.467px 49.467px 49.467px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(49.46666717529297px);
}

.software-approach-item h2 {
  margin-bottom: 20px;
  font-family: "Rubik";
  color: #071d6a;
  transition: color 0.3s ease;
}

.software-approach-item h2:hover {
  color: #fbcc34;
  cursor: pointer;
}

.software-approach-item p {
  font-size: 1rem;
  font-weight: 400;
  color: #333 !important;
  font-family: "Rubik";
  /* text-align: justify; */
  /* letter-spacing: 2px; */
}

/* Technologies and Tools Section */
.technologiess {
  padding: 0 100px;
}
.training-approach-head {
  margin-bottom: 1.5em;
}
.tech-contain {
  padding: 40px 20px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.5);
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.show-list {
  margin-top: 80px;
  background-color: rgb(124, 124, 124);
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 15px;
}
.show-list-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  color: #ffffff;
  cursor: pointer;
  border-radius: 15px;
}
.wantto-know {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  transition: transform 0.3s ease-in-out;
}
.wantto-know:hover {
  transform: scale(1.1);
}
.wantto-know-btn {
  width: 25%;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .home-to-software {
    padding: 0 20px;
  }

  .large-centered-heading {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }

  .smaller-centered-heading {
    font-size: 1.2rem;
  }

  .section-heading {
    font-size: 1.2rem;
  }

  .section-details {
    font-size: 0.9rem;
  }

  .boxed-image {
    max-width: 400px;
  }

  .software-approach-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .software-approach-list {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 490px) {
  .soft-hero-image {
    margin: 75px 0;
  }
}

@media (max-width: 600px) {
  /* Home to software section */
  .home-to-software {
    padding: 0 20px;
  }
  .software-banner {
    border-radius: 10px;
    background: #071d6a;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .soft-banner {
    padding: 0 20px;
    padding-top: 3em;
    padding-bottom: 6em;
  }

  .software-approach-heads {
    text-align: center;
    margin-bottom: 1em;
    font-family: "Rubik";
    color: #ffb100;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .over {
    color: #f0f0f0;
    font-weight: 600;
    /* text-align: justify; */
  }
  .soft-paragraph {
    color: rgba(240, 240, 240, 0.8);
    text-align: left;
    letter-spacing: 0;
    font-size: 15px;
  }

  .home-to-software-p {
    font-family: "Rubik";
    font-size: 1rem;
    color: rgba(240, 240, 240, 0.8);
    margin-bottom: 0;
  }
  .banner-image {
    position: absolute;
    max-width: 100%;
    top: 34rem;
  }
  .soft-hero-image {
    width: 410px;
    height: 220px;
  }
  .wantto-know-btn {
    width: 75%;
  }

  .home-to-software {
    padding: 0 10px;
  }

  .software-approach-main {
    padding: 0 10px;
  }

  .wantto-know-btn {
    width: 90%;
  }

  .service-card-container {
    flex-direction: column;
    align-items: center;
  }
  .training-approach-main {
    margin-bottom: 50px;
    margin-top: 17rem;
  }
  /* tools and technology */
  .technologiess {
    padding: 0 20px;
  }
  .training-approach-head {
    margin-bottom: 1.5em;
    text-align: center;
  }
}
