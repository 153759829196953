.feedback-section {
  margin-bottom: 50px;
}

.testBg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 50vh; */
  height: auto;
}

.test-testimonials {
  height: 350px;
}

.test-read {
  /* color: #12d6d6; */
  color: #071d6a;
  text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
  font-size: 2.5rem;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 600;
  font-family: "Rubik";
  padding-top: 1.2em;
  padding-bottom: 1.5em;
}

.test-testimonial-details {
  padding: 0 50px;
  text-align: left;
  font-size: 1.2rem;
  color: #333;
  font-family: "Rubik";
  letter-spacing: 2px;
}
/* .Slider {
  color: #ffffff;
} */
.slick-prev,
.slick-next {
  width: 60px;
  height: 60px;
  /* background-color: #12d6d6; */
  z-index: 10;
}

.slick-prev:before,
.slick-next:before {
  /* color: #ffffff; Arrow color */
  color: #071d6a;
  font-size: 40px; /* Arrow size */
}

.slick-prev {
  left: -40px; /* Adjust left arrow position */
}

.slick-next {
  right: -40px; /* Adjust right arrow position */
}

.test-quote {
  text-align: left;
}

.author {
  margin-top: 10px;
  color: #071d6a;
  padding-left: 50px;
}

.drone-contact-uss {
  padding-left: 50px;
  margin-top: 50px;
}

.drone-contactuss-button {
  width: 18%;
  transition: transform 0.3s ease-in-out;
}

.drone-contactuss-button:hover {
  transform: scale(1.1);
}

/*Tablet:  On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
}

/*mobile:  On screens that are 600px) or less, set the background color to blue */
@media screen and (max-width: 600px) {
  .testBg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 54vh;
    /* height: auto; */
  }

  .test-read {
    color: #071d6a;
    text-shadow: 0px 4px 4px rgba(255, 177, 0, 0.25);
    font-size: 1.5rem;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 600;
    font-family: "Rubik";
    padding: 1.2em 1em;
  }

  .test-testimonial-details {
    padding: 0 40px;
    text-align: justify;
    font-size: 1rem;
    /* letter-spacing: 2px; */
  }

  .test-quote {
    text-align: justify;
    font-size: 1rem;
    letter-spacing: 0;
  }

  .author {
    margin-top: 10px;
    color: #071d6a;
    padding-left: 50px;
    text-align: center;
    font-size: 1rem;
  }

  .slick-prev,
  .slick-next {
    width: 55px;
    height: 60px;
    z-index: 10;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #071d6a;
    font-size: 30px; /* Arrow size */
  }

  .slick-prev {
    left: -40px; /* Adjust left arrow position */
  }

  .slick-next {
    right: -40px; /* Adjust right arrow position */
  }
}
